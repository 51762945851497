@font-face {
	font-family: 'text-security-disc';
	src: url('text_security_font/textsecuritydisc.eot');
	src: url('text_security_font/textsecuritydisc.eot')
			format('embedded-opentype'),
		url('text_security_font/textsecuritydisc.woff2') format('woff2'),
		url('text_security_font/textsecuritydisc.woff') format('woff'),
		url('text_security_font/textsecuritydisc.ttf') format('truetype'),
		url('text_security_font/textsecuritydisc.svg#textsecuritydisc')
			format('svg');
}
